#root {
  min-width : 100vw;
  min-height: 100vh;
  overflow  : hidden;
}

*,
html {
  margin : 0;
  padding: 0;
}

.form {
  height               : 72px;
  border-radius        : 14px;
  /* border            : 1px solid rgba(251, 251, 251, 0.3); */
  background           : transparent;
  /* background        : rgba(110, 175, 217, 0.1); */
  backdrop-filter      : blur(107px);
  position             : relative;
  width                : 500px !important;
}

textarea {
  width                   : 500px;
  outline                 : transparent;
  resize                  : none;
  border                  : none;
  overflow                : auto;
  -webkit-box-shadow      : none;
  -moz-box-shadow         : none;
  box-shadow              : none;
  /* margin-left          : 15px; */
  /* margin-right         : 5px; */
  /* margin-top           : 15px; */
  display                 : block;
  width                   : 100%;
  /* height               : 100%; */
  background              : transparent;
  color                   : aliceblue;
  padding                 : 20px;
  border-bottom           : 1px solid #fff !important;
}

.button {
  position     : absolute;
  right        : 7.5rem;
  top          : .9rem;
  border-radius: 20px;
}

.button img {
  border-radius: 20px;
  width        : 30px;
  height       : 30px;
}

/* .mic_style {
  position: relative;
  right   : .5rem;
  cursor  : pointer;
} */
.mic_style {
  display        : flex;
  align-items    : center;
  justify-content: center;
  cursor         : pointer;
  width          : 25%;
  z-index        : 2;
  position       : absolute;
  right          : 1rem;
}

/* .language-select {
  position     : relative;
  right        : .8rem;
  top          : 0rem;
  width        : 50px;
  height       : 40px;
  border-radius: 10px;
  color        : #19232d;
  margin-left  : 1rem;
} */

.language-select {
  left         : .5rem;
  top          : 0rem;
  width        : 50px;
  height       : 40px;
  border-radius: 10px;
  color        : #19232d;
  margin-left  : 1rem;
}

.form_texterea {
  position       : absolute;
  top            : 82vh;
  left           : 2.5vw;
  width          : 500px;
  height         : 120px;
  border-radius  : 10px;
  background     : rgba(0, 0, 0, 0.7);
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}


@media screen and (max-width:500px) {

  .container-chat1,
  .form_texterea {
    max-width: 90% !important;
    width    : 100% !important;
  }
}





.copy {
  position       : absolute;
  bottom         : 7px;
  /* height      : 10px; */
  color          : aliceblue;
  text-align     : center;
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 100%;

}

.chat-History {

  max-height: 600px;
  overflow-y: scroll;
}

.box-iframe {
  width   : 100vw;
  height  : 100vh;
  position: relative;
  overflow: hidden;
}

body {
  margin: 0;
}

.container {
  display       : flex;
  flex-direction: column;
}

.container-chat {
  display      : flex;
  align-items  : center;
  width        : 100%;
  /* height    : 100%; */
  overflow     : auto;
  text-align   : left;
  margin       : 0px 15px;
}



.chat-Historyc {
  background-color: rgba(0, 0, 0, 0.7);
  /* border-radius: 20%; */
  width           : 3.5vw;
  height          : 3.5vw;
}

.chat-Historyo {
  background-color: rgba(0, 0, 0, 0.7);
  /* border-radius: 4%; */
  width           : 30vw;
  height          : 45vh;
}

/* ::placeholder {
  color: white;
  text-align: center;
  opacity: 1;
} */

.placeholder1::placeholder {
  color  : white;
  opacity: 1;
}

.placeholder2::placeholder {
  color     : rgba(219, 168, 66, 255);
  text-align: center;
}

.placeholder3::placeholder {
  color    : white;
  opacity  : 1;
  font-size: 15px;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.icon {
  position       : absolute;
  margin-left    : 50px;
  margin-top     : 30px;
  display        : flex;
  justify-content: space-between;
  width          : 13px;
  height         : 13px;


}

.icon span {
  width           : 3px;
  height          : 100%;
  background-color: green;
  border-radius   : 3px;
  content         : '';
}

@keyframes bounce {
  10% {
    transform: scaleY(0.3);
    /* start by scaling to 30% */
  }

  30% {
    transform: scaleY(1);
    /* scale up to 100% */
  }

  60% {
    transform: scaleY(0.5);
    /* scale down to 50% */
  }

  80% {
    transform: scaleY(0.75);
    /* scale up to 75% */
  }

  100% {
    transform: scaleY(0.6);
    /* scale down to 60% */
  }
}

.span-prop {
  width           : 3px;
  height          : 100%;
  background-color: orange;
  border-radius   : 3px;
  animation       : bounce 2.2s ease infinite alternate;
  content         : '';
}

span {
  &:nth-of-type(2) {
    animation-delay: -2.2s;
    /* Start at the end of animation */
  }

  &:nth-of-type(3) {
    animation-delay: -3.7s;
    /* Start mid-way of return of animation */
  }
}

.ChatBubble {
  position    : absolute;
  top         : 1vh;
  left        : 1vw;
  z-index     : 1;
  width       : 100%;
  /* max-width: 900px; */
}